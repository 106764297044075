//Student 2 is for 2nd year(batch:2026) 
//Student 3 is for 3rd year (batch:2025)
//Student 4 is for 4th year(batch:2024)




let student3=[
        {
            name:"Ankur Yadav",
            email:"ankuryadav9978@gmail.com",
            domain:"Art and Craft",
            linkedin:"https://www.linkedin.com/in/ankur-yadav-085102255",
            instagram:"https://instagram.com/ankur_19yadav?igshid=MTk0NTkyODZkYg==",
            image:"./Student_images/Ankur_Yadav.jpg",
        },
        {
            name:"Antriksh Bansal",
            email:"antriksh16.b@gmail.com",
            domain:"Oratory",
            linkedin:"https://www.linkedin.com/in/%C3%A1ntriksh-bansal-0a0343232?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
            instagram:"https://instagram.com/antrikshhhh?igshid=YTQwZjQ0NmI0OA==",
            image:"./Student_images/Antriksh_Bansal.jpg",
        },
        {
            name:"Aseem Jain",
            email:"jainaseem2004@gmail.com",
            domain:"Web Development",
            linkedin:"https://www.linkedin.com/in/aseem-jain-258608252/",
            instagram:"https://www.instagram.com/aseem2847/",
            image:"./Student_images/Aseem_Jain.jpg",
        },
        {
            name:"Aviral Pandey",
            email:"hackthic@gmail.com",
            domain:"Sales and Marketing",
            linkedin:"https://www.linkedin.com/in/aviral-pandey-hackthic/",
            instagram:"https://www.instagram.com/iaviral_pandey/",
            image:"./Student_images/Aviral_Pandey.jpg",
        },
        {
            name:"Kartik Ranaut",
            email:"ranautk6@gmail.com",
            domain:"Web Development",
            linkedin:"https://www.linkedin.com/in/kartik-ranaut/",
            instagram:"https://www.instagram.com/kartikranaut/",
            image:"./Student_images/Kartik_Ranaut.jpg",
        },
        {
            name:"Palak Agarwal",
            email:"palakagarwal304@gmail.com",
            domain:"Oratory",
            linkedin:"https://in.linkedin.com/in/palak-agarwal-4a932924a",
            instagram:"https://instagram.com/palak.agarwalp?igshid=MzMyNGUyNmU2YQ%3D%3D&utm_source=qr",
            image:"./Student_images/Palak_Agarwal.jpg",
        },
        {
            name:"Prachi Mogha",
            email:"moghaprachi@gmail.com",
            domain:"Photography",
            linkedin:"https://www.linkedin.com/in/prachi-mogha-a55732227?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
            instagram:"https://instagram.com/prachi_mogha?igshid=MWlxNWdwOWU3bHRrdg==a",
            image:"./Student_images/Prachi_Mogha.jpg",
        },
        {
            name:"Prakhar Maurya",
            email:"prakhar.myself@gmail.com",
            domain:"Designing",
            linkedin:"https://www.linkedin.com/in/prakharmauryas",
            instagram:"https://www.instagram.com/in/prakharmauryas",
            image:"./Student_images/Prakhar_Maurya.jpg",
        },
        {
            name:"Rashi Jaiswal",
            email:"rjaiz1820@gmail.com",
            domain:"Art and Craft",
            linkedin:"https://www.linkedin.com/in/rashi-jaiswal-064486247",
            instagram:"Instagram.com/_jaiswal_rashi_",
            image:"./Student_images/Rashi_Jaiswal.jpg",
        },
        {
            name:"Siddharth Gupta",
            email:"siddharthg627@gmail.com",
            domain:"Photography",
            linkedin:"https://www.linkedin.com/in/siddharth-gupta-585960245?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
            instagram:"https://instagram.com/ig_kingslayer.sg?igshid=MXdoeGFhNGYxNDlyOQ==",
            image:"./Student_images/Siddharth_Gupta.jpg",
        }
]


let student4=[
    {
        name:"Abhishek Patel",
        email:"Abhishek962851@gmail.com",
        domain:"Photography",
        linkedin:"https://www.linkedin.com/in/abhishek-patel-30ba69211",
        instagram:"https://www.instagram.com/invites/contact/?i=1mrmp0urz2xq0&utm_content=ks38wqm",
        image:"./Student_images/Abhishek_Patel.jpg",
    },
    {
        name:"Anjali Tiwari",
        email:"tanjali16aug@gmail.com",
        domain:"Oratory",
        linkedin:"www.linkedin.com/in/anjali-tiwari-clock8",
        instagram:"https://www.instagram.com/mirror__clock__7/",
        image:"./Student_images/Anjali_Tiwari.jpg",
    },
    {
        name:"Harshit Vishnoi",
        email:"harshit.vishnoi5588@gmail.com",
        domain:"Photography",
        linkedin:" www.linkedin.com/in/harshit-vishnoi-0b4aa7206",
        instagram:"https://www.instagram.com/camcus_man",
        image:"./Student_images/Harshit_Vishnoi.jpg",
    },
    {
        name:"Swati Saxena",
        email:"Swatisaxena5104@gmail.com",
        domain:"Oratory",
        linkedin:"www.linkedin.com/in/swati-saxena-1b6335213",
        instagram:"https://www.instagram.com/swatisaxena5104/",
        image:"./Student_images/Swati_Saxena.jpg",
    }
]

export default student3;
