let student2=[
    {
        name:"Aayush Gautam",
        email:"aayush17gautam@gmail.com",
        domain:"Management",
        linkedin:"https://www.linkedin.com/in/aayush-gautam-bb674324a?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
        instagram:"https://instagram.com/__aayush_gautam_?igshid=OGQ5ZDc2ODk2ZA==",
        image:"./Student_images/Aayush_Gautam.jpg",
    },
    {
        name:"Abhijeet Pandey",
        email:"pandeyabhijeet155@gmail.com",
        domain:"Oratory",
        linkedin:"https://www.linkedin.com/in/abhijeet-pandey-7535601b4/",
        instagram:"https://www.instagram.com/_abhijeeetttt/",
        image:"./Student_images/Abhijeet_Pandey.jpg",
    },
    {
        name:"Ankit Pandey",
        email:"ankitpandit022003@gmail.com",
        domain:"Oratory",
        linkedin:"https://www.linkedin.com/in/ankit-pandey-473197233?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
        instagram:"https://instagram.com/ankit8643pandey?igshid=MzMyNGUyNmU2YQ==",
        image:"./Student_images/Ankit_Pandey.jpg",
    },
    {
        name:"Asmit Bhan",
        email:"askasmit7@gmail.com",
        domain:"Designing",
        linkedin:"https://www.linkedin.com/in/asmit-bhan-7769a6213?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
        instagram:"https://www.instagram.com/abhi._.1522/",
        image:"./Student_images/Asmit_Bhan.jpg",
    },
    {
        name:"Devang Saraswat",
        email:"saraswatdevang18@gmail.com ",
        domain:"Oratory",
        linkedin:"https://www.linkedin.com/in/devang-saraswat-62316a1a7",
        instagram:"https://instagram.com/devangsaraswat?igshid=NzZlODBkYWE4Ng==",
        image:"./Student_images/Devang_Saraswat.jpg",
    },
    {
        name:"Divyansh Verma",
        email:"divyansh81155@gmail.com",
        domain:"Photography",
        linkedin:"https://www.linkedin.com/in/divyansh-verma-321287299?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
        instagram:"https://instagram.com/me_divyanxh?igshid=OGQ5ZDc2ODk2ZA==",
        image:"./Student_images/Divyansh_Verma.jpg",
    },
    {
        name:"Durgendra Singh",
        email:"singhdurgendra2003@gmail.com",
        domain:"Web Development",
        linkedin:"https://www.linkedin.com/in/durgendra-singh-3761b6277?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
        instagram:"https://instagram.com/durgendra_11?utm_source=qr&igshid=eGxhMGhicTVmdG4=",
        image:"./Student_images/Durgendra_Singh.jpg",
    },
    {
        name:"Harsh Pandey",
        email:"harshpandey86ai.r@gmail.com",
        domain:"Web Development",
        linkedin:"https://www.linkedin.com/in/harshpandey006?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
        instagram:"https://www.instagram.com/harshpandey006/",
        image:"./Student_images/Harsh_Pandey.jpeg",
    },
    {
        name:"Harsh Verma",
        email:"harshvermaofficial0@gmail.com",
        domain:"Oratory",
        linkedin:"https://www.linkedin.com/in/harsh-verma-494b38255?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
        instagram:"https://instagram.com/leharshverma?igshid=NzZlODBkYWE4Ng==",
        image:"./Student_images/Harsh_Verma.jpg",
    },
    {
        name:"Harshit Pal",
        email:"harshitobra19@gmail.com",
        domain:"Management",
        linkedin:"https://www.linkedin.com/in/harshit-pal-13b3b0276?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
        instagram:"https://instagram.com/harshit_pal_19?utm_source=qr&igshid=MThlNWY1MzQwNA==",
        image:"./Student_images/Harshit_Pal.jpg",
    },
    {
        name:"Malavika Mishra",
        email:"malavikamishra89@gmail.com",
        domain:"Oratory",
        linkedin:"https://www.linkedin.com/in/malavika-mishra-57a445254?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
        instagram:"https://instagram.com/mishramalavika?igshid=NzZlODBkYWE4Ng==",
        image:"./Student_images/Malavika_Mishra.jpg",
    },
        {
        name:"Palak",
        email:"sharmamanik825@gmail.com",
        domain:"Oratory",
        linkedin:"https://www.linkedin.com/in/palak-sharma-a66942256",
        instagram:"https://instagram.com/_._palash?igshid=aW5ueXpiNDJob2J3",
        image:"./Student_images/Palak.jpg",
    },
    {
        name:"Prakhar Tripathi",
        email:"prakkhhaarr@gmail.com",
        domain:"Oratory",
        linkedin:"https://www.linkedin.com/in/prakhar-tripathi-03655a282?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
        instagram:"https://instagram.com/prakkhharr_?igshid=OGQ5ZDc2ODk2ZA==",
        image:"./Student_images/Prakhar_Tripathi.jpg",
    },
    {
        name:"Priyanshu Srivastava",
        email:"priyanshuyash0809@gmail.com",
        domain:"Management",
        linkedin:"https://www.linkedin.com/in/priyanshu-srivastava-41ba03257?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
        instagram:"https://instagram.com/_priyanshu.sri_08?igshid=cDdhbnVrNXJhOGNp",
        image:"./Student_images/Priyanshu_Srivastava.jpg",
    },
    {
        name:"Raj Aryan",
        email:"official.aryan101@gmail.com",
        domain:"Web Development",
        linkedin:"www.linkedin.com/in/raj-aryan001",
        instagram:"https://www.instagram.com/raj_aryanx/",
        image:"./Student_images/Raj_Aryan.jpg",
    },
    {
        name:"Rupak Koushik",
        email:"rupakkaushik2004@gmail.com",
        domain:"Oratory",
        linkedin:"www.linkedin.com/in/rupak-koushik-79686b297",
        instagram:"https://www.instagram.com/x_rupak._/",
        image:"./Student_images/Rupak_Koushik.jpg",
    },
    {
        name:"Sahil Shrivastav",
        email:"yashshrivastav2004@gmail.com",
        domain:"Web Development",
        linkedin:"https://www.linkedin.com/in/sahil-shrivastava-a300b525a/",
        instagram:"https://www.instagram.com/yash_2004.ss/",
        image:"./Student_images/Sahil_Shrivastava.jpg",
    },
    {
        name:"Sarthak Singh",
        email:"Sarthaksingh602@gmail.com",
        domain:"Photography",
        linkedin:"https://www.linkedin.com/in/sarthak-singh-015064271?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
        instagram:"https://instagram.com/_.sarthaksingh._?igshid=ZXFqYjlhNjR0aG5l",
        image:"./Student_images/Sarthak_Singh.jpg",
    },
    {
        name:"Shivansh Tiwari",
        email:"sachintiwari.edu@gmail.com",
        domain:"Management",
        linkedin:"https://www.linkedin.com/in/shivansh-tiwari-21166b272?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
        instagram:"https://instagram.com/shivanshtiwari5740?igshid=YzAwZjE1ZTI0Zg==",
        image:"./Student_images/Shivansh_Tiwari.jpg",
    },
    {
        name:"Shreya Garg",
        email:"shreyanrg2405@gmail.com",
        domain:"Art and Craft",
        linkedin:"https://www.linkedin.com/in/shreya-garg-a3b83b29b?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
        instagram:"https://www.instagram.com/shreyagarg2410/?next=%2F#",
        image:"./Student_images/Shreya_Garg.jpg",
    },
    {
        name:"Shreya Saxena",
        email:"shreyasaxena1203@gmail.com",
        domain:"Sales and Marketing",
        linkedin:"https://www.linkedin.com/in/shreya-saxena-2b5077252?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
        instagram:"https://instagram.com/_shreyayayaya._?igshid=MTFnb3dqZW5oZTdmNw==",
        image:"./Student_images/Shreya_Saxena.jpg",
    },
    {
        name:"Vaishnavi Varma",
        email:"raginidisha2004@gmail.com",
        domain:"Art and Craft",
        linkedin:"https://www.linkedin.com/in/vaishnavi-varma-3ba307294?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
        instagram:"https://instagram.com/vaishnavi_2004varma?igshid=MTMzZnNlaW1xOG9ibQ==",
        image:"./Student_images/Vaishnavi_Varma.jpg",
    },
    {
        name:"Vedanshi Agarwal",
        email:"vedanshi27vishu@gmail.com",
        domain:"Web Development",
        linkedin:"www.linkedin.com/in/vedanshi-agarwal-a5b89425a",
        instagram:"https://instagram.com/vedanshi27vishu?igshid=c3Q3dmk3ZG92c2hy",
        image:"./Student_images/Vedanshi_Agarwal.jpg",
    },
    {
        name:"Yash Srivastava",
        email:"yashsrivastava819@gmail.com",
        domain:"Web Development",
        linkedin:"https://www.linkedin.com/in/yash-srivastava-509a32217?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
        instagram:"https://instagram.com/thenameisyash_0808?utm_source=qr&igshid=OGIxMTE0OTdkZA==",
        image:"./Student_images/Yash_Srivastava.jpg",
    }
]

export default student2;