

let student4=[
    {
        name:"Abhishek Patel",
        email:"Abhishek962851@gmail.com",
        domain:"Photography",
        linkedin:"https://www.linkedin.com/in/abhishek-patel-30ba69211",
        instagram:"https://www.instagram.com/invites/contact/?i=1mrmp0urz2xq0&utm_content=ks38wqm",
        image:"./Student_images/Abhishek_Patel.jpg",
    },
    {
        name:"Anjali Tiwari",
        email:"tanjali16aug@gmail.com",
        domain:"Oratory",
        linkedin:"www.linkedin.com/in/anjali-tiwari-clock8",
        instagram:"https://www.instagram.com/mirror__clock__7/",
        image:"./Student_images/Anjali_Tiwari.jpg",
    },
    {
        name:"Harshit Vishnoi",
        email:"harshit.vishnoi5588@gmail.com",
        domain:"Photography",
        linkedin:" www.linkedin.com/in/harshit-vishnoi-0b4aa7206",
        instagram:"https://www.instagram.com/camcus_man",
        image:"./Student_images/Harshit_Vishnoi.jpg",
    },
    {
        name:"Swati Saxena",
        email:"Swatisaxena5104@gmail.com",
        domain:"Oratory",
        linkedin:"www.linkedin.com/in/swati-saxena-1b6335213",
        instagram:"https://www.instagram.com/swatisaxena5104/",
        image:"./Student_images/Swati_Saxena.jpg",
    }
]

export default student4;