let data =[
    
    {
        id:1,
        name:"Conscious Engineering Workshop",
        image:"https://nypost.com/wp-content/uploads/sites/2/2023/04/shutterstock_1177507720.jpg?quality=75&strip=all&w=744",
        text:"The Conscious Engineering Workshop explores self-awareness and purpose-driven living. Participants engage in transformative practices, cultivating resilience and empathy, ultimately empowering them to engineer a conscious and fulfilling life.",        color:"red"
    },
    {
        id:2,
        name:"Relationship Management Workshop",
        color:"orange",
        image:"https://www.mywellnesshub.in/blog/wp-content/uploads/2022/11/A-father-and-his-son.jpeg",
        text:"The workshop delves into the science of relationships, employing self-exploration to understand interpersonal dynamics. Participants navigate emotional intelligence, fostering more meaningful relationships."    
    },
    {
        id:3,
        name:"Shramdaan",
        color:"pink",
        image:"https://media.istockphoto.com/id/1201112520/photo/planting-tree-in-garden-concept-save-world-green-earth.jpg?s=612x612&w=0&k=20&c=nYvD6suoUax7iOhi8vxc5X-oHtS5C2Ifu6x9LWrrdgg=",
        text:"Shramdaan is voluntary community service involving physical labor. Individuals contribute their time and effort for a shared cause, fostering collective well-being."    },
    {
        id:4,
        name:"Sustainable Production Center",
        color:"green",
        image:"https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEgvOoYrFijjBd9Vfv8YJk4YdfTqbQHEIBDYVJ95UAwRud8rBTMNP56mUDP1OUc-bo14YBYwD0Ib3LcUajG5ss8v4Thmzt5zSupCjrZSRVg16vD-Wg6Rtrl06ptVIrmgsPzOWoz77kkKQcHbHXzrIfjX6D6cFgfnCFUtFHSLTo05cElKxsJJ-bvZPLQggA/s2240/Sustainable%20Development%20for%20Future%20Generation.png",
        text:"The Sustainable Production Centre creates organic soaps, prioritizing eco-friendly practices for a minimal environmental impact while promoting health and sustainability."    },
    {
        id:5,
        name:"Samriddhi Pravaah",
        color:"red",
        image:"https://img.freepik.com/free-photo/donate-sign-charity-campaign_53876-127165.jpg?size=626&ext=jpg&ga=GA1.1.1412446893.1704758400&semt=sph",
        text:"Samriddhi Pravaah encourages donating items for communal use, promoting a culture of sharing and ensuring resources benefit those in need."    },
    {
        id:6,
        color:"red",
        name:"Drishti",
        image:"https://spotlightonline.co/wp-content/uploads/2017/03/cinema_projector.jpg",
        text:"Drishti promotes values through educational short movie, fostering personal growth and ethical understanding in an accessible and engaging format.",
    },    
    {
        id:7,
        name:"Mutually Fulfilling Communication",
        color:"red",
        image:" https://www.marketing91.com/wp-content/uploads/2018/03/Types-of-Communication-4.jpg",
        text:"Mutually Fulfilling Communication workshop cultivates effective, respectful dialogue, emphasizing mutual understanding and collaboration for positive and satisfying interpersonal interactions.",
    },
    {
        id:8,
        name:"Kartavya",
        color:"red",
        image:"https://www.ugaoo.com/cdn/shop/articles/shutterstock_417312760.jpg?v=1661869375",
        text:"Kartavya involves planting trees as a responsibility to save nature, contributing to environmental conservation and fostering sustainable ecosystems for the future.",
    }  

]

export default data;